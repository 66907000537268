<template>
    <div class="meditation-descr">

        <div class="meditation-descr__title">
            {{descrData.name}}
        </div>

        <div class="meditation-descr__text" v-if="isFeedbackForm">
            <div class="meditation-descr__text-inner opened">
                <span v-html="descrData.discrText"></span>
            </div>
        </div>

        <div class="meditation-descr__text" v-else>
            <div class="meditation-descr__text-inner" :class="{'opened': isOpened}">
                <span v-html="descrData.discrText"></span>
            </div>

            <div class="show-more" @click="toggleText" v-html="getMoreBtnText()"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'MeditationDescr',
  props: {
    text: String,
    title: String,
  },
  components: {},
  data() {
    return {
      complete: false,
      isOpened: false,
    };
  },
  computed: {
    isFeedbackForm() {
      return this.$route.params.id === 'eto-ne-konec-vse-tolko-nachinaetsya';
    },
    descrData() {
      return this.$store.state.meditations.currentMeditation;
    },
    groupDescrData() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
  },
  methods: {
    toggleText() {
      this.isOpened = !this.isOpened;
    },
    getPreviewTitle() {
      return this.descrData.type === 'mediatation' ? 'Прослушать' : 'Посмотреть';
    },
    getMoreBtnText() {
      return this.isOpened ? 'Свернуть' : 'Подробнее';
    },
    showDialog(name) {
      this.$store.commit('setCurrentTemplate', name);
      this.$store.commit('showDialog');
    },
    showPlayerPreview() {
      this.$store.commit('showPlayer');

      if (typeof window.fbq !== 'undefined' && !this.descrData.isPayed) {
        window.fbq('track', 'ViewContent', {
          content_ids: [this.descrData.id],
          currency: 'RUB',
          content_type: 'product',
          value: this.descrData.discrPrice,
        });
      }
    },
  },
  watch: {
    $route() {
      this.isOpened = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";

.meditation-descr {
    padding: 15px 24px;

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 15px;

        @media only screen and (min-width: 768px) {
            font-size: 22px;
            line-height: 36px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;

        @media only screen and (min-width: 768px) {
            font-size: 14px;
            line-height: 21px;
        }

        p {
            margin: 12px 0;

            &:first-child {
                margin-top: 0;
            }
        }

        .show-more {
            color: $main-blue;
            margin-top: 5px;
            cursor: pointer;
            display: inline-block;
            font-family: $textFont;
        }
    }

    &__text-inner {
        max-height: 60px;
        overflow: hidden;
        transition: all 300ms;
        font-family: $textFont;
        color: $darkGray;

        @media only screen and (min-width: 768px) {
            max-height: 63px;
        }

        &.opened {
            max-height: 1200px;
        }
    }

    &__table {
        margin: 25px 0;
    }

    &__line {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        line-height: 38px;
        border-bottom: 1px solid rgba($lightGray, 0.2);
        cursor: pointer;

        @media only screen and (min-width: 768px) {
            line-height: 50px;
        }

        &:first-child {
            border-top: 1px solid rgba($lightGray, 0.2);
        }
    }

    &__th {
        font-size: 12px;
        font-weight: 500;

        @media only screen and (min-width: 768px) {
            font-size: 16px;
        }

    }

    &__td {
        color: $gray;
        font-size: 14px;
        text-align: right;
        font-family: $textFont;

        @media only screen and (min-width: 768px) {
            font-size: 16px;
        }

    }

    &__arrow-icon {
        background: url('/images/arrow-right-small.svg');
        width: 5px;
        height: 8px;
        display: inline-block;
        margin-left: 10px;

        @media only screen and (min-width: 768px) {
            transform: scale(1.5);
            margin-left: 12px;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
        position: relative;
        top: -1px;

        @media only screen and (min-width: 768px) {
            transform: scale(1.3);
            margin-right: 7px;
        }

        &.timer {
            background: url("/images/timer-icon-black.svg") no-repeat 50% 50%;
        }

        &.reviews {
            background: url("/images/review-icon-black.svg") no-repeat 50% 50%;
        }

        &.price {
            background: url("/images/price-icon-black.svg") no-repeat 50% 50%;
        }

        &.sale {
            background: url("/images/sale-icon-black.svg") no-repeat 50% 50%;
        }

        &.listen {
            background: url("/images/how-to-listen.svg") no-repeat 50% 50%;
        }
    }
}
</style>
