<template>
  <div class="meditation-group-page">
    <div class="meditation-group-page__header">
      <BaseSecondaryHeader :title="groupData.title"
                           :back-params="this.groupData.groupName"
      ></BaseSecondaryHeader>
      <MeditationsList></MeditationsList>
    </div>
    <meditationDescr></meditationDescr>
    <meditationActionButtons></meditationActionButtons>
    <BasePayment></BasePayment>
    <BaseAudioPlayer v-if="isPlayerVisible"></BaseAudioPlayer>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSecondaryHeader from '@/components/header/BaseSecondaryHeader.vue';
import MeditationsList from '@/components/meditation/meditationsList.vue';
import meditationDescr from '@/components/meditation/meditationDescr.vue';
import BasePayment from '@/components/payment/BasePayment.vue';
import meditationActionButtons from '@/components/meditation/meditationActionButtons.vue';
import BaseAudioPlayer from '@/components/meditation/BaseAudioPlayer.vue';

export default {
  name: 'Home',
  components: {
    BaseSecondaryHeader,
    MeditationsList,
    meditationDescr,
    meditationActionButtons,
    BasePayment,
    BaseAudioPlayer,
  },
  data() {
    return {
    };
  },
  beforeMount() {
    this.$store.commit('setCurrentPage', {
      group: this.$route.params.id,
      name: this.$route.params.meditationId,
    });

    document.documentElement.style.setProperty('--main-color', this.groupData.mainColor);
    this.checkIsWebinarSaleEnabled();
  },
  computed: {
    isPlayerVisible() {
      return this.$store.state.player.isVisible;
    },
    groupData() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
  },
  methods: {
    checkIsWebinarSaleEnabled() {
      const endTime = localStorage.getItem('webinarSaleTime');
      const endTime3Days = localStorage.getItem('webinarSaleTime3Days');

      if (endTime) {
        const currentTime = Date.parse(new Date());
        const endTimeTimeStamp = Date.parse(endTime.replace('-', '/').replace('-', '/'));

        if (currentTime > endTimeTimeStamp) {
          this.$store.commit('setLandingSaleTimeEnabled', false);
        } else {
          this.$store.commit('setLandingSaleTimeEnabled', true);
        }
      }

      if (endTime3Days) {
        const currentTime = Date.parse(new Date());
        const endTimeTimeStamp = Date.parse(endTime3Days.replace('-', '/').replace('-', '/'));

        if (currentTime > endTimeTimeStamp) {
          this.$store.commit('setLandingSaleTimeEnabled', false);
        } else {
          this.$store.commit('setLandingSaleTimeEnabled', true);
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.$store.commit('setCurrentPage', {
        group: to.params.id,
        name: to.params.meditationId,
      });

      document.documentElement.style.setProperty('--main-color', this.groupData.mainColor);
    },
  },
};
</script>

<style lang="scss">

  .meditation-group-page {
    padding-bottom: 140px;

    &__header {
      height: 150px;
      background: url('/images/groups-bg/inner-group-default.jpeg') no-repeat;
      background-size: cover;
      position: relative;

      @media only screen and (min-width: 768px) {
        height: 160px;
      }

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        bottom: -1px;
        left: 0;
        z-index: 5;
        height: 100px;
        background: linear-gradient(to top, rgba(255,255,255,1), rgba(0,0,0,0));
      }
    }
  }
</style>
