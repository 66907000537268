<template>
    <div class="base-player"
         :class="{'opened': isVisible}"
         :style="{ 'background-image': 'url(' + currentMeditationGroup.playerImage + ')' }"
    >
        <div class="base-player__cover"></div>
        <div class="base-player__close-btn" @click="hidePlayer"></div>

        <div class="videoPLayer"  v-if="currentMeditation.type === 'webinar'">
            <div class="base-player__youTube" v-if="isYouTube">
                <iframe
                        width="100%"
                        height="315"
                        :src="getYouTubeUrl()"
                        frameborder="0"
                        allowfullscreen
                ></iframe>
            </div>
            <div id="player"
                 data-plyr-provider="youtube"
                 :data-plyr-embed-id="getVideoUrl()"
                 v-else
            ></div>
        </div>
        <div class="audioPlayer" v-else>
            <audio id="player" controls>
                <source :src="getUrl" type="audio/mp3" />
            </audio>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';
import NoSleep from 'nosleep.js';

export default {
  name: 'BasePlayer',
  components: {},
  data() {
    return {
      player: '',
      isVisible: false,
      noSleep: '',
      isYouTube: false,
    };
  },
  computed: {
    mediationInfo() {
      return this.$store.state.meditations.currentMeditation;
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    isLandingPage() {
      return this.$store.state.user.landingUserType !== '';
    },
    getUrl() {
      const data = this.$store.state.meditations.currentMeditation;
      let returnLink = data.isPayed ? data.audio : data.audioPreview;

      if (this.isLandingPage) {
        returnLink = data.audioPreview;
      }

      return returnLink;
    },
  },
  methods: {
    hidePlayer() {
      this.$store.commit('hidePlayer');
    },
    getVideoUrl() {
      const data = this.$store.state.meditations.currentMeditation;

      return data.isPayed ? data.audio : data.audioPreview;
    },
    getYouTubeUrl() {
      const data = this.$store.state.meditations.currentMeditation;
      const url = data.isPayed ? data.audio : data.audioPreview;

      return `https://www.youtube.com/embed/${url}`;
    },
  },
  mounted() {
    const isYouTube = window.innerWidth <= 768;

    if (isYouTube && this.currentMeditation.type === 'webinar') {
      this.isYouTube = true;
    } else {
      const playerId = 'player';
      this.player = new Plyr(`#${playerId}`, {
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: false,
          container: null,
        },
      });

      this.player.on('exitfullscreen', () => {
        document.querySelector('.plyr__video-embed.plyr__video-wrapper')
          .style.removeProperty('padding-bottom');
      });
    }

    this.noSleep = new NoSleep();
    this.noSleep.enable();

    if (this.currentMeditation.type === 'webinar') {
      this.isVisible = true;
    } else {
      setTimeout(() => {
        const element = document.querySelector('.plyr__progress__container');
        element.setAttribute('data-text', this.mediationInfo.name);
        this.isVisible = true;
      }, 100);
    }
  },
  beforeDestroy() {
    this.noSleep.disable();
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/player";

.base-player {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('/images/groups-bg/player.jpg') $white 50% 50%;
    background-size: cover;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms;
    max-width: 767px;

    /* ipad */
    @media only screen and (min-width: 768px) {
        left: calc(50% - 384px);
    }

    &.opened {
        opacity: 1;
        visibility: visible;
    }

    .videoPLayer,
    .audioPlayer {
        position: relative;
        z-index: 5;
    }

    &__youTube {
        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__cover {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        z-index: 2;
    }

    &__close-btn {
        width: 24px;
        height: 24px;
        background: $main-blue;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 18px;
        z-index: 7;

        &:before {
            width: 100%;
            height: 100%;
            display: flex;
            background:
                    url("/images/close-icon-white.svg") no-repeat 50% 50%;
            content: '';
            background-size: 8px;
        }
    }
}

</style>
