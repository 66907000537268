<template>
    <div class="meditation-actions" v-if="!isFeedbackForm">
        <div class="meditation-actions__bottom-descr">
            Нажимайте на кнопки, чтобы давать мне обратную связь и участвовать в улучшении проекта.
        </div>

        <div class="meditation-actions__main-buttons">
            <button class="es-button" @click="markAsComplete(currentMeditation.id)">
                Полезно
            </button>
            <button class="es-button gray" @click="markAsInProgress(currentMeditation.id)">
                Бесполезно
            </button>
        </div>

        <template v-if="currentUserPackage === 'premium'">
            <a href="https://t.me/joinchat/wxNdEj_KvbpiODky"
               target="_blank"
               class="es-button es-button--bordered"
            >
                Обсудить в Телеграме
            </a>
        </template>

        <template v-else>
            <button class="es-button es-button--bordered"
                    @click="startFullCoursePayment('premium')"
            >
                Задать вопрос
            </button>
        </template>
    </div>
</template>

<script>

export default {
  name: 'MediatationActionButtons',
  components: {},
  data() {
    return {
      complete: false,
      isPixelCartEventSended: false,
      isUserAuthed: this.$store.getters.getUserMail !== '',
    };
  },
  watch: {
    $route() {
      this.isPixelCartEventSended = false;
    },
  },
  computed: {
    isFeedbackForm() {
      return this.$route.params.id === 'eto-ne-konec-vse-tolko-nachinaetsya';
    },
    currentUserPackage() {
      return this.$store.state.user.currentUserPackage;
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    preparedInfoCourse() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    preparedInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    preparedCourseInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isCurrentPackagePayed() {
      return this.$store.getters.isCurrentPackagePayed;
    },
  },
  methods: {
    markAsComplete(id) {
      this.$store.commit('setAsComplete', id);
      this.$store.dispatch('saveTasksState');
    },
    markAsInProgress(id) {
      this.$store.commit('setAsInProgress', id);
      this.$store.dispatch('saveTasksState');
    },
    startPayment() {
      this.$store.commit('showPayment');
      this.$store.commit('setPackagesSwithcer', true);
      this.$store.commit('markMeditationForPay', {
        isFullPackageSelected: this.$store.state.payment.isFullPackageSelected,
      });

      if (typeof window.fbq !== 'undefined' && !this.isPixelCartEventSended) {
        window.fbq('track', 'AddToCart', {
          content_ids: [this.currentMeditation.id],
          content_type: 'product',
          currency: 'RUB',
          value: this.currentMeditation.discrPrice,
        });

        this.isPixelCartEventSended = true;
      }
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    startFullCoursePayment() {
      this.$store.commit('markCourseMeditationsForPay');
      this.$store.commit('setLandingPaymentPriceToPay', 3000);
      this.$store.commit('setLandingPaymentType', 'premium');
      this.$store.commit('setPriceToPay', 3000);
      this.$store.commit('setLandingPaymentFullPrice', 3000);
      this.$store.commit('setLandingPagePayment', true);
      this.$store.commit('showPayment');

      if (typeof window.fbq !== 'undefined' && !this.isPixelCartEventSended) {
        window.fbq('track', 'AddToCart', {
          content_ids: [this.currentMeditation.id],
          content_type: 'product',
          currency: 'RUB',
          value: '3000',
        });

        this.isPixelCartEventSended = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.meditation-actions {
    position: fixed;
    bottom: 0;
    padding: 0 24px 15px 24px;
    width: 100%;
    max-width: $mobile-max ;
    box-sizing: border-box;
    background: rgba($white, 0.9);

    .es-button {
        z-index: 5;
        position: relative;
    }

    &__main-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        & > button {
            margin: 0 8px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &:before {
        position: absolute;
        height: 30px;
        width: 100%;
        left: 0;
        top: -30px;
        background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(222, 222, 222, 0));
        content: '';
    }

    &__bottom-descr {
        font-family: $textFont;
        color: $darkGray;
        font-size: 12px;
        line-height: 19px;
    }

    @media only screen and (min-width: 768px) {
        padding: 20px;

        &:before {
            width: 100%;
            height: 100%;
            background-color: var(--main-color);
            opacity: 0.15;
            z-index: 0;
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            display: block;
        }
    }
}

 .es-button {
     background: var(--main-color);
     z-index: 5;

     &--bordered {
         border: solid var(--main-color) 1px;
         color: var(--main-color);
         background: $white;
     }

     &--link {
         background: transparent;
         line-height: 20px;
         font-size: 12px;
         height: 20px;
     }
 }
</style>
